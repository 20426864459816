import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios'



// // firebase start
// import firebase from 'firebase/app';

// const firebaseConfig = {
//   apiKey: "AIzaSyDydqZBGoIscO1olfgxwi4yTnXlY1hhsEc",
//   authDomain: "autopp-ffab4.firebaseapp.com",
//   projectId: "autopp-ffab4",
//   storageBucket: "autopp-ffab4.appspot.com",
//   messagingSenderId: "1096917581605",
//   appId: "1:1096917581605:web:53ce6dda06e359ddb664cc",
//   measurementId: "G-KJ8MQ1EGP8"
// };

// firebase.initializeApp(firebaseConfig);
// firebase end


axios.defaults.baseURL = "https://api.protou.ch"
// axios.defaults.baseURL = "http://localhost:8000"
// axios.defaults.baseURL = "http://192.168.1.228:8000"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
