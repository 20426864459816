/* eslint-disable no-unused-vars */
import './App.css'
import { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react'
import axios from 'axios'
import utils from './utils.js'
import { ReactComponent as LogoSvg } from './img/logo.svg'
import { ReactComponent as DropSvg } from './img/drop.svg'
import { ReactComponent as PreviewSvg } from './img/preview.svg'
import { ReactComponent as DownloadSvg } from './img/download.svg'
import { ReactComponent as CloseSvg } from './img/close.svg'
import ImageBlobReduce from "image-blob-reduce"
import Pica from "pica"
// import { StyledFirebaseAuth } from 'react-firebaseui'
// import firebase from 'firebase/app'
// import 'firebase/auth'
import piexif from 'piexifjs'
import FirefoxExtImgSrc from './img/firefox.png'
import ChromeExtImgSrc from './img/chrome.png'
// import Worker from './worker'
// import WebGLDebugUtils_ from './webgl-debug';
// const WebGLUtils = WebGLDebugUtils_.WebGLDebugUtils()

const getReducer = () => {
  const pica = Pica({ features: ["js", "wasm" /*, "ww"*/] });
  const reducer = new ImageBlobReduce({ pica });

  reducer._create_blob = function (env) {
    return this.pica.toBlob(env.out_canvas, 'image/jpeg', 1.0)
      .then(function (blob) {
        env.out_blob = blob;
        return env;
      });
  };

  return reducer
}

const vs = `#version 300 es
  precision highp float;
  in vec4 a_position;

  void main() {
    gl_Position = a_position;
  }
`

const fs = `#version 300 es
  precision highp float;
  precision highp sampler2D;
  precision highp sampler3D;

  uniform vec2 u_resolution;
  uniform vec2 u_photo_resolution;
  uniform sampler2D u_photo;
  uniform sampler3D u_clut;
  uniform bool u_apply;
  uniform bool u_has_matrix;
  uniform bool u_clut_has_loaded;
  // uniform bool u_make_square;
  // uniform float u_exposure;
  uniform float u_magnitude;
  uniform vec3 u_offset;
  uniform mat3 u_rotation;
  uniform float u_magnitude_input;
  uniform vec3 u_offset_input;
  uniform mat3 u_rotation_input;
  // uniform vec3 u_src_avg_color;
  // uniform vec3 u_src_avg_color_input;
  uniform float u_effectiveness;

  out vec4 fragColor;

  float dist(vec2 p0, vec2 pf){return sqrt((pf.x-p0.x)*(pf.x-p0.x)+(pf.y-p0.y)*(pf.y-p0.y));}

  void main() {
    vec2 coord = gl_FragCoord.xy;
    vec2 photo_res = u_photo_resolution.xy;
    vec2 res = u_resolution.xy;

    // if(u_apply && u_make_square) {
    //   float shortest_edge = min(photo_res.x, photo_res.y);
    //   photo_res = vec2(shortest_edge);

    //   res *= 1.0 + photo_res.y / photo_res.x;
    // }

    vec2 scale = photo_res / res;

    float maxScale = max(scale.x, scale.y);

    vec2 uv = coord/photo_res * maxScale;

    // uv *= 1.25;
    // uv /= 0.8;

    // if(u_apply && u_make_square) {
    //   uv.y += 0.5;
    //   uv.x += 0.1;
    // }

    vec3 c = vec3(0.0);

    // if(u_apply) {
    uv.y = 1.0 - uv.y;
    // }

    c = texture(u_photo, uv).rgb;

    // if(u_apply && u_clut_has_loaded && !u_make_square) {
    //   // vignette
    //   // c = vec3(0.5);
    //   uv -= 0.5;
    //   float d = dist(uv*1.6, uv)*0.4;
    //   // if(uv.x > 0.0) {
    //     c = mix(c + vec3(0.0), vec3(0.0), d - 0.05);
    //   // }
    // }

    if(u_apply && u_clut_has_loaded) {
      if(uv.x > 0.0) {

        vec3 p = c.bgr;
        vec3 orig_p = p;
        // vec3 p = c;

        // float brightness_a = (u_src_avg_color.r + u_src_avg_color.g + u_src_avg_color.b) / 3.0;
        // float brightness_input = (u_src_avg_color_input.r + u_src_avg_color_input.g + u_src_avg_color_input.b) / 3.0;

        // float brightness_scale = brightness_a / brightness_input;
        // brightness_scale = clamp(brightness_scale, 0.5, 2.0);

        // if(uv.x > 0.5)
          // p *= brightness_scale;


        // transform to normal space
        if(u_has_matrix) {
          p -= u_offset_input / 64.0;
          p /= u_magnitude_input;
          p = inverse(u_rotation_input) * p;
  
          // p -= 0.0005 * brightness_scale;
          
  
          // transform to target image space (reverse)
          p = (u_rotation) * p;
          p *= u_magnitude;
          p += u_offset / 64.0;
        }

        
        bool overexposed = false;
        if(p.r >= 1.0 && p.g >= 1.0 && p.b >= 1.0) {
          overexposed = true;
        }

        
        bool underexposed = false;
        if(p.r <= 0.0 && p.g <= 0.0 && p.b <= 0.0) {
          underexposed = true;
        }

        vec3 new_color = texture(u_clut, p).rgb;
        vec3 old_color = orig_p.bgr;

        c = mix(old_color, new_color, u_effectiveness);

        // if(overexposed) {
        //   c = vec3(1.0, 0.0, 0.0);
        // } else if(underexposed) {
        //   c = vec3(0.0, 0.0, 1.0);
        // }
        
      } else {
        // c = texture(u_clut, c.bgr).rgb;
      }
    }


    fragColor = vec4(c, 1.0);
  }
`


const FileView = ({ onPhotoOrientationChange }) => {
  const [scores, setScores] = useState()
  const [inputMeta, setInputMeta] = useState()
  const [fullImage, setFullImage] = useState()
  const [smallImageFile, setSmallImageFile] = useState()
  const smallImageFileWasSetRef = useRef(false)
  const [mainImage, setMainImage] = useState()
  const [previewImage, setPreviewImage] = useState()
  const [clutList, setClutList] = useState([])
  const containerRef = useRef()
  const wrapperRef = useRef()
  const firstPreviewWrapperRef = useRef()
  const [file, setFile] = useState()
  const [activeClutIndex, setActiveClutIndex] = useState(-1)
  const [isDroppingFile, setIsDroppingFile] = useState(false)
  const didScrollRef = useRef(false)
  const [disablePreview, setDisablePreview] = useState(false)
  const [viewWidth, setViewWidth] = useState(-1)
  const [isUploading, setIsUploading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [effectiveness, setEffectiveness] = useState(1.0)
  // const workerRef = useRef(new Worker())
  const reducerRef = useRef(getReducer())
  // const downloadButtonRef = useRef()

  // expose setFile function for extension use
  useEffect(() => {
    if (window.extensionFile !== undefined) {
      setIsUploading(true)
      setTimeout(() => {
        setFile(window.extensionFile)
      }, 100)
    }

    window.setFile = (file) => {
      setIsUploading(true)
      setTimeout(() => {
        setFile(file)
      }, 100)
    }
  }, [])

  const onFileChange = e => {
    if (e.target.files.length === 0) {
      console.log('No files selected')
      return
    }

    setIsUploading(true)

    window.gtag('event', 'file_upload')

    // if (downloadButtonRef.current) {
    //   downloadButtonRef.current.scrollIntoView({ behavior: "smooth" })
    // }

    setTimeout(() => {
      setFile(e.target.files[0])
    }, 100)
  }

  const scrollToPhoto = useCallback(() => {
    if (containerRef.current && file) {
      didScrollRef.current = true
      containerRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [file])


  // const scrollToDownloadButton = useCallback(() => {
  //   if (downloadButtonRef.current && mainImage) {
  //     console.log("scrolling")
  //     didScrollRef.current = true
  //     downloadButtonRef.current.scrollIntoView({ behavior: "smooth" })
  //   }
  // }, [mainImage])


  /* -- auto scroll START -- */
  const containerHeightRef = useRef(0)
  const onContainerResize = (height) => {
    if (containerHeightRef.current !== height) {
      const diff = height - containerHeightRef.current
      if (diff > 0) {
        window.scrollBy(0, diff * 0.7)
        containerHeightRef.current = height
      }
    }
  }

  const containerResizeObserverRef = useRef()
  useEffect(() => {
    // create container resize observer
    if (!containerResizeObserverRef.current && containerRef.current) {
      containerHeightRef.current = containerRef.current.clientHeight

      containerResizeObserverRef.current = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (entry.contentBoxSize) {
            // Firefox implements `contentBoxSize` as a single content rect, rather than an array
            const contentBoxSize = Array.isArray(entry.contentBoxSize) ?
              entry.contentBoxSize[0] :
              entry.contentBoxSize

            onContainerResize(contentBoxSize.blockSize)
          } else {
            onContainerResize(entry.contentRect.height)
          }
        }
      })

      containerResizeObserverRef.current.observe(containerRef.current)
    }

  }, [containerRef, containerResizeObserverRef, mainImage])
  /* -- auto scroll END -- */


  // useEffect(() => {
  //   // when file has loaded, scroll down
  //   if (!didScrollRef.current) {
  //     containerRef.current.addEventListener("transitionend", () => {
  //       scrollToDownloadButton()
  //     }, { once: true})
  //   }
  // }, [containerRef, didScrollRef, file, scrollToDownloadButton])

  // resize observer for updating size state
  const resizeTimerRef = useRef()
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        let width;
        if (entry.contentBoxSize) {
          // Firefox implements `contentBoxSize` as a single content rect, rather than an array
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ?
            entry.contentBoxSize[0] :
            entry.contentBoxSize

          width = contentBoxSize.inlineSize
        } else {
          width = entry.contentRect.width
        }

        if (resizeTimerRef.current) {
          clearTimeout(resizeTimerRef.current)
        }

        resizeTimerRef.current = setTimeout(() => {
          setViewWidth(width)
        }, 100)
      }
    })

    resizeObserver.observe(containerRef.current)
  }, [containerRef])


  const hasFetchedCluts = useRef(false)
  useEffect(() => {
    // when scores have loaded, fetch cluts

    if (!scores || hasFetchedCluts.current) {
      return
    }

    hasFetchedCluts.current = true

    const fetchClutForScore = (iterator) => {
      const [i, item] = iterator.next().value

      if (i > 4) {
        return
      }

      const { score, meta } = item

      const formData = new FormData()
      formData.append('clutname', meta.src_name + ".png")

      axios
        .post('clut3d', formData, { responseType: 'arraybuffer' })
        .then(r => {
          const clut = {
            data: new Uint8Array(r.data),
            name: meta.src_name,
            score,
            meta
          }

          setClutList(x => {
            x[i + 1] = clut
            return [...x]
          })

          fetchClutForScore(iterator)
        })
    }

    const iterator = scores.entries()
    fetchClutForScore(iterator)
  }, [scores, clutList])


  const loadImage = async (file) => {
    const dataURL = await utils.fileAsDataURL(file)
    const image = await utils.dataURLAsImage(dataURL)
    const ratio = image.height / image.width
    const reducer = reducerRef.current

    const orientation = image.width > image.height ? 'landscape' : 'portrait'
    onPhotoOrientationChange(orientation)

    // small image (for api lut match) (doesn't need to be done on resize)
    if (!smallImageFileWasSetRef.current) {
      smallImageFileWasSetRef.current = true
      let small = await reducer.toBlob(file, { max: 400 })
      if (file.name.endsWith('.jpg') || file.name.endsWith('.jpeg')) {
        small = await utils.stripExifFromFile(small)
      }
      setSmallImageFile(small)
    }

    // nice-looking main view size image
    {
      const longestEdge = ratio > 1.0 ?
        wrapperRef.current.clientWidth * ratio :
        wrapperRef.current.clientWidth

      // const resizedImage = await workerRef.current.resizeFile(file, { max: longestEdge * window.devicePixelRatio })
      const resizedFile = await reducer.toBlob(file, { max: longestEdge * window.devicePixelRatio })
      const resizedDataUrl = await utils.fileAsDataURL(resizedFile)
      const resizedImage = await utils.dataURLAsImage(resizedDataUrl)
      setMainImage(resizedImage)
    }

    // nice-looking preview size image
    {
      let longestEdge
      if (image.width > image.height) {
        const actualHeight = firstPreviewWrapperRef.current.clientHeight * 1.2
        const actualWidth = actualHeight / ratio
        longestEdge = actualWidth
      } else {
        const actualWidth = firstPreviewWrapperRef.current.clientWidth * 1.2
        const actualHeight = actualWidth * ratio
        longestEdge = actualHeight
      }

      const resizedFile = await reducer.toBlob(file, { max: longestEdge * window.devicePixelRatio })
      const resizedDataUrl = await utils.fileAsDataURL(resizedFile)
      const resizedImage = await utils.dataURLAsImage(resizedDataUrl)

      setPreviewImage(resizedImage)
    }

    // full size image (run it through reducer to fix android metadata rotation)
    {
      const resizedFile = await reducer.toBlob(file)
      const resizedDataUrl = await utils.fileAsDataURL(resizedFile)
      const resizedImage = await utils.dataURLAsImage(resizedDataUrl)
      setFullImage(resizedImage)
    }
  }

  useEffect(() => {
    // when file has loaded, generate Image
    if (!file) {
      return
    }

    setTimeout(() => {
      loadImage(file)
    }, 100)
  }, [file, onPhotoOrientationChange, viewWidth])


  useEffect(() => {
    // when file has loaded, fetch scores

    if (!file || scores || !smallImageFile) {
      return
    }

    if (!file.type.match(/image.*/)) {
      return
    }

    const formData = new FormData()
    formData.append('file', smallImageFile, file.name)

    axios.post('scores', formData).then(r => {
      const { scores, input_meta } = r.data[0]

      setScores(scores)
      setInputMeta(input_meta)
    })
  }, [file, smallImageFile, scores])

  useEffect(() => {
    if (!file || !smallImageFile) {
      return
    }

    if (!file.type.match(/image.*/)) {
      return
    }

    const formData = new FormData()
    formData.append('file', smallImageFile, file.name)

    // and generate ml clut
    axios
      .post('generate', formData, { responseType: 'arraybuffer' })
      .then(r => {
        const clut = {
          data: new Uint8Array(r.data),
          name: 'generated',
          score: 0.0,
          meta: null
        }

        setClutList(x => {
          x[0] = clut
          return [...x]
        })
      })
  }, [file, smallImageFile])


  const setActiveClut = (i) => {
    window.gtag('event', 'pick_clut')
    setActiveClutIndex(i)
  }

  const onFileDragEnter = () => {
    setIsDroppingFile(true)
  }

  const onFileDragLeave = () => {
    setIsDroppingFile(false)
  }

  let foundOneLoading = false

  const startDisablePreview = () => {
    setDisablePreview(true)

    window.addEventListener('pointerup', () => {
      setDisablePreview(false)
    }, { once: true })
  }

  const reset = () => {
    if (clutList[3] === undefined)
      return

    hasFetchedCluts.current = false
    setClutList([])
    setFile()
    setPreviewImage()
    setMainImage()
    setSmallImageFile()
    setFullImage()
    setInputMeta()
    setScores()
    setActiveClutIndex(-1)
    setIsDroppingFile(false)
    // setEffectiveness(1.0)
    didScrollRef.current = false
    setDisablePreview(false)
    setIsUploading(false)
    onPhotoOrientationChange('landscape')
    smallImageFileWasSetRef.current = false
    // const [viewWidth, setViewWidth] = useState(-1)
  }

  const download = (e) => {
    if (isDownloading || !fullImage) {
      return
    }

    window.gtag('event', 'download_photo')

    const secretActive = e.metaKey && e.shiftKey

    setIsDownloading(true)

    setTimeout(async () => {
      const clut = clutList[activeClutIndex]

      let image = fullImage

      if (secretActive) {
        const resizedFile = await reducerRef.current.toBlob(file, { max: 1000 })
        const resizedDataUrl = await utils.fileAsDataURL(resizedFile)
        image = await utils.dataURLAsImage(resizedDataUrl)
      }

      var canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      let gl = canvas.getContext('webgl2', { antialias: false, alpha: false, preserveDrawingBuffer: true })
      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)
      const program = utils.createProgramFromSources(gl, [vs, fs])

      renderCanvas(program, gl, clut, image, inputMeta, effectiveness)

      const truncate = (str, n) => (str.length > n) ? str.substr(0, n - 1) : str
      const newFilename = truncate(file.name, 32).split('.')[0] + '_Protouch.jpg'

      const dataUrl = canvas.toDataURL('image/jpeg', 1.0)


      utils.fileAsDataURL(file).then((dataURL) => {
        const loadedExif = piexif.load(dataURL)

        if (loadedExif['0th'] === undefined) {
          loadedExif['0th'] = {}
        }

        loadedExif['0th'][piexif.ImageIFD.Software] = "Protouch Instant Process"

        if (loadedExif['0th'][piexif.ImageIFD.Orientation] !== undefined) {
          delete loadedExif['0th'][piexif.ImageIFD.Orientation]
        }

        const exifStr = piexif.dump(loadedExif)
        const dataUrlWithExif = piexif.insert(exifStr, dataUrl)

        var hiddenElement = document.createElement('a')
        hiddenElement.href = dataUrlWithExif
        hiddenElement.target = '_blank'
        hiddenElement.download = newFilename
        hiddenElement.onblur = (e) => {
          console.log("onblur", e)
        }
        hiddenElement.click()
      })

      setTimeout(() => {
        setIsDownloading(false)
      }, 1500)
    }, 100)
  }

  const onEffectivenessChange = useCallback((value) => {
    setEffectiveness(value / 100.0)
  }, [])

  return (
    <div className="photoview">

      <div ref={containerRef} className={`mainphoto-container ${fullImage ? 'loaded' : ''}`}>

        {<div className={`downloading-overlay ${isDownloading ? 'active' : ''}`}>
          <DownloadSvg />
        </div>}

        <div className={`effectiveness-slider`}>
          <input type="range" min="0" max="100" defaultValue={100} onChange={(e) => onEffectivenessChange(e.target.value)} />
        </div>

        {clutList[3] && <div className="reset-button" onClick={reset}><CloseSvg /></div>}
        <div className={`download-button ${mainImage ? 'loaded' : ''} ${isDownloading ? 'downloading' : ''}`} onClick={download}><DownloadSvg /></div>
        <div className={`canvas-wrapper ${isDroppingFile ? "dropping" : ""} ${!mainImage ? "image-not-loaded" : ""}`}
          ref={wrapperRef}
        >
          {(mainImage) ?
            <GLView
              photo={mainImage}
              makeSquare={false}
              clut={(!disablePreview && activeClutIndex > -1) ? clutList[activeClutIndex] : null}
              inputMeta={inputMeta}
              initialHeight={wrapperRef.current.clientHeight}
              initialWidth={wrapperRef.current.clientWidth}
              // onClick={scrollToPhoto}
              onPointerDown={startDisablePreview}
              onTouchEnd={() => setDisablePreview(false)}
              effectiveness={effectiveness}
            /> : isUploading
              ? <div className='uploading-loader'>
                <PreviewSvg />
              </div>
              : <label className="custom-file-upload">
                <input type='file'
                  accept='image/*'
                  onChange={onFileChange}
                  onDragEnter={onFileDragEnter}
                  onDragLeave={onFileDragLeave}
                  onDrop={onFileDragLeave}
                  onBlur={onFileDragLeave}
                />
                <div className="custom-file-upload-content">
                  <DropSvg />
                  <span className="drop-text">Drop a photo</span>
                  <div className="drop-or">
                    <div className="drop-separator" />
                    <span>or</span>
                    <div className="drop-separator" />
                  </div>
                  <span className="file-upload-button">Pick a file</span>
                </div>
              </label>
          }
        </div>
      </div>
      <div className="previews">
        {[0, 1, 2, 3].map(i => {
          const hasLoaded = inputMeta && clutList[i]

          const loading = fullImage && !hasLoaded && !foundOneLoading

          if (loading) {
            foundOneLoading = true
          }

          const active = activeClutIndex === i

          return (
            <div
              className={`preview-container preview ${(loading || hasLoaded) ? 'loading' : ''} ${active ? 'active' : ''}`}
              key={i}
              id={clutList[i] ? clutList[i].name : ''}
            >
              <PreviewSvg />
              <div className='preview-wrapper' ref={i === 0 ? firstPreviewWrapperRef : null}>
                {(inputMeta && clutList[i] && previewImage) && (
                  <GLView
                    photo={previewImage}
                    clut={clutList[i]}
                    inputMeta={inputMeta}
                    makeSquare={false}
                    onPointerDown={() => setActiveClut(i)}
                    // onMouseOver={() => setActiveClut(i)}
                    // onMouseLeave={() => setActiveClut(-1)}
                    initialHeight={100}
                    initialWidth={100}
                    effectiveness={1.0}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>)
}


const renderCanvas = (program, gl, clut, photo, inputMeta, effectiveness) => {
  const clutData = clut ? clut.data : null
  const clutHasLoaded = clutData ? true : false
  const meta = clut ? clut.meta : null

  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)

  const positionAttributeLocation = gl.getAttribLocation(
    program,
    'a_position'
  )
  const resolutionLocation = gl.getUniformLocation(program, 'u_resolution')
  const photoResolutionLocation = gl.getUniformLocation(
    program,
    'u_photo_resolution'
  )
  const photoLocation = gl.getUniformLocation(program, 'u_photo')
  const clutLocation = gl.getUniformLocation(program, 'u_clut')
  const applyLocation = gl.getUniformLocation(program, 'u_apply')
  const hasMatrixLocation = gl.getUniformLocation(program, 'u_has_matrix')
  const clutHasLoadedLocation = gl.getUniformLocation(program, 'u_clut_has_loaded')
  const magnitudeLocation = gl.getUniformLocation(program, 'u_magnitude')
  const offsetLocation = gl.getUniformLocation(program, 'u_offset')
  const rotationLocation = gl.getUniformLocation(program, 'u_rotation')
  const magnitudeInputLocation = gl.getUniformLocation(program, 'u_magnitude_input')
  const offsetInputLocation = gl.getUniformLocation(program, 'u_offset_input')
  const rotationInputLocation = gl.getUniformLocation(program, 'u_rotation_input')
  const effectivenessLocation = gl.getUniformLocation(program, 'u_effectiveness')
  // const srcAvgColorLocation = gl.getUniformLocation(program, 'u_src_avg_color')
  // const srcAvgColorInputLocation = gl.getUniformLocation(program, 'u_src_avg_color_input')

  const positionBuffer = gl.createBuffer()

  gl.enableVertexAttribArray(positionAttributeLocation)
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)

  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([-1, -1, 1, -1, -1, 1, -1, 1, 1, -1, 1, 1]),
    gl.STATIC_DRAW
  )

  gl.useProgram(program)
  gl.enableVertexAttribArray(positionAttributeLocation)
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)
  gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0)


  // clut texture
  var clutTexture = gl.createTexture()
  gl.activeTexture(gl.TEXTURE0 + 1)
  gl.bindTexture(gl.TEXTURE_3D, clutTexture)
  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1)
  gl.texImage3D(
    gl.TEXTURE_3D,
    0,
    gl.RGB,
    64,
    64,
    64,
    0,
    gl.RGB,
    gl.UNSIGNED_BYTE,
    clutData
  )
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_WRAP_R, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)


  // texture
  var texture = gl.createTexture()
  gl.activeTexture(gl.TEXTURE0 + 0)
  gl.bindTexture(gl.TEXTURE_2D, texture)
  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1)

  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, gl.RGB, gl.UNSIGNED_BYTE, photo)

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)



  // draw
  gl.useProgram(program)

  gl.uniform1i(photoLocation, 0)
  gl.uniform1i(clutLocation, 1)
  gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height)
  gl.uniform2f(photoResolutionLocation, photo.width, photo.height)
  gl.uniform1i(applyLocation, 0)
  gl.uniform1i(hasMatrixLocation, 0)
  gl.uniform1i(clutHasLoadedLocation, clutHasLoaded)
  // gl.uniform1i(makeSquareLocation, makeSquare)
  // gl.uniform1f(exposureLocation, clut ? clut.meta.exposure : 0.0)
  // gl.uniform1f(exposureLocation, 0.0)

  if (meta) {
    gl.uniform1f(magnitudeLocation, meta.matrix_magnitude)
    gl.uniform3f(offsetLocation, ...meta.matrix_offset)
    gl.uniformMatrix3fv(rotationLocation, false, meta.matrix_rotation.flat())
    // gl.uniform3f(srcAvgColorLocation, ...meta.src_avg_color)
  } else {
    gl.uniform1f(magnitudeLocation, 1.0)
    gl.uniform3f(offsetLocation, 0.0, 0.0, 0.0)
    gl.uniformMatrix3fv(rotationLocation, false, [1, 0, 0, 0, 1, 0, 0, 0, 1])
    // gl.uniform3f(srcAvgColorLocation, 0, 0, 0)
  }

  if (meta && inputMeta) {
    gl.uniform1f(magnitudeInputLocation, inputMeta.matrix_magnitude)
    gl.uniform3f(offsetInputLocation, ...inputMeta.matrix_offset)
    gl.uniformMatrix3fv(rotationInputLocation, false, inputMeta.matrix_rotation.flat())
    // gl.uniform3f(srcAvgColorInputLocation, ...inputMeta.src_avg_color)
  } else {
    gl.uniform1f(magnitudeInputLocation, 1.0)
    gl.uniform3f(offsetInputLocation, 0.0, 0.0, 0.0)
    gl.uniformMatrix3fv(rotationInputLocation, false, [1, 0, 0, 0, 1, 0, 0, 0, 1])
    // gl.uniform3f(srcAvgColorInputLocation, 0, 0, 0)
  }

  gl.uniform1i(applyLocation, 1)
  gl.uniform1i(hasMatrixLocation, (meta && inputMeta) ? 1 : 0)

  gl.uniform1f(effectivenessLocation, effectiveness)

  gl.drawArrays(gl.TRIANGLES, 0, 6)
}

const GLView = forwardRef((
  { photo, clut, inputMeta, makeSquare, initialWidth, initialHeight, effectiveness, ...props }, forwardRef) => {
  const clutData = clut ? clut.data : null
  const meta = clut ? clut.meta : null

  const canvasRef = useRef()
  useImperativeHandle(forwardRef, () => canvasRef.current)

  const wrapperRef = useRef()
  const [physicalSize, setPhysicalSize] = useState([
    initialWidth * window.devicePixelRatio,
    initialHeight * window.devicePixelRatio])
  const programRef = useRef()
  const clutHasLoaded = clutData ? true : false

  const renderWidth = physicalSize[0] / window.devicePixelRatio
  const renderHeight = physicalSize[1] / window.devicePixelRatio

  const lastResizeWidthRef = useRef(0)
  const handleWindowResize = useCallback(() => {

    if (wrapperRef.current) {
      const { clientWidth, clientHeight } = wrapperRef.current

      if (lastResizeWidthRef.current === clientWidth) {
        return
      }

      lastResizeWidthRef.current = clientWidth

      if (makeSquare) {
        const scaleX = clientWidth / photo.width
        // const scaleY = clientHeight / photo.height
        const scale = Math.min(1.0, scaleX)
        const longestEdge = Math.max(photo.width, photo.height)
        setPhysicalSize([longestEdge * scale, longestEdge * scale])
      } else {
        const scaleX = clientWidth / photo.width
        // const scaleY = clientHeight / photo.height
        // const scale = Math.min(1.0, scaleX, scaleY)
        // setPhysicalSize([photo.width * scale, photo.height * scale])

        // setPhysicalSize([clientWidth, photo.height * scaleX])
        setPhysicalSize([photo.width, photo.height])
      }
    }
  }, [photo, makeSquare])

  useEffect(() => {
    handleWindowResize()
  }, [wrapperRef, handleWindowResize])

  useEffect(() => {
    if (canvasRef.current) {
      wrapperRef.current = canvasRef.current.parentElement
      handleWindowResize()
    }
  }, [canvasRef, handleWindowResize])

  useEffect(() => {
    window.addEventListener('resize',
      handleWindowResize)
  }, [handleWindowResize])

  useEffect(() => {
    if (!wrapperRef.current || !canvasRef.current) {
      return
    }

    const canvas = canvasRef.current

    let gl = canvas.getContext('webgl2', { antialias: false, alpha: false, preserveDrawingBuffer: true })

    // function validateNoneOfTheArgsAreUndefined(functionName, args) {
    //   for (var ii = 0; ii < args.length; ++ii) {
    //     if (args[ii] === undefined) {
    //       console.error("undefined passed to gl." + functionName + "(" +
    //         WebGLUtils.glFunctionArgsToString(functionName, args) + ")");
    //     }
    //   }
    // }
    // function logGLCall(functionName, args) {
    //   console.log("gl." + functionName + "(" +
    //     WebGLUtils.glFunctionArgsToString(functionName, args) + ")");
    // }
    // function throwOnGLError(err, funcName, args) {
    //   throw WebGLUtils.glEnumToString(err) + " was caused by call to: " + funcName;
    // };
    // function logAndValidate(functionName, args) {
    //   // logGLCall(functionName, args);
    //   validateNoneOfTheArgsAreUndefined(functionName, args);
    // }
    // gl = WebGLUtils.makeDebugContext(gl, throwOnGLError, logAndValidate);


    if (!programRef.current) {
      programRef.current = utils.createProgramFromSources(gl, [vs, fs])
    }
    const program = programRef.current

    renderCanvas(program, gl, clut, photo, inputMeta, effectiveness)
  }, [photo, clut, physicalSize, clutHasLoaded, makeSquare, renderWidth, renderHeight, inputMeta, meta])

  useEffect(() => {
    if (!wrapperRef.current || !canvasRef.current) {
      return
    }
    
    if (!programRef.current) {
      return
    }
    
    const program = programRef.current

    const canvas = canvasRef.current

    let gl = canvas.getContext('webgl2', { antialias: false, alpha: false, preserveDrawingBuffer: true })

    const effectivenessLocation = gl.getUniformLocation(program, 'u_effectiveness')
    gl.uniform1f(effectivenessLocation, effectiveness)
    gl.drawArrays(gl.TRIANGLES, 0, 6)
  }, [effectiveness])

  return (
    <canvas
      ref={canvasRef}
      // width={renderWidth}
      // height={renderHeight}

      width={physicalSize[0]}
      height={physicalSize[1]}

      // style={{ width: physicalSize[0], height: physicalSize[1] }}
      // style={{ width: renderWidth, height: renderHeight }}

      {...props}
    />
  )
})

const PingFailureMessage = () => {
  return (<p className='failure-message'>
    The connection to the server timed out.<br />
    If this issue persists, please report it to me at <a href="mailto:carl@protou.ch">carl@protou.ch</a>.
  </p>)
}

const Webgl2FailureMessage = () => {
  return (<p className='failure-message'>
    This application uses &nbsp;
    <a href="https://get.webgl.org/webgl2/" rel="noreferrer" target="_blank">WebGL2</a>
    , which your browser doesn’t support.<br />
    Updating your device or browser may solve this problem.<br />
    If you have questions, feel free to contact me at <a href="mailto:carl@protou.ch">carl@protou.ch</a>.
  </p>)
}


// const Login = () => {
//   const uiConfig = {
//     signInFlow: 'redirect',
//     signInOptions: [
//       { provider: "apple.com" },
//       firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//       firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//       firebase.auth.EmailAuthProvider.PROVIDER_ID,
//     ],
//     callbacks: {
//       signInSuccessWithAuthResult: (authResult) => {
//         const { additionalUserInfo, credential, operationType, user } = authResult
//         const { isNewUser, providerId } = additionalUserInfo

//         return false
//       }
//     }
//   };

//   return <div className='login'>
//     {/* <p>Sign in to get started right away</p> */}
//     <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
//   </div>
// }

const FirefoxExtensionLink = () =>
  <a target="_blank" href="https://addons.mozilla.org/en-US/firefox/addon/send-to-protouch/">
    <img className="ext-link" src={FirefoxExtImgSrc} />
  </a>

const ChromeExtensionLink = () =>
  <a target="_blank" href="https://chrome.google.com/webstore/detail/send-to-protouch/dcolmfkbgobcgknlnklphgenmnnckepm">
    <img className="ext-link" src={ChromeExtImgSrc} />
  </a>

const App = () => {
  const [pingSuccess, setPingSuccess] = useState(true)
  const [photoOrientation, setPhotoOrientation] = useState('landscape')
  const [isWebGLSupported] = useState(() => document.createElement('canvas').getContext('webgl2') ? true : false)
  // const [user, setUser] = useState()
  // const [firebaseInitialized, setFirebaseInitialized] = useState(false)
  const isFirefoxRef = useRef(typeof InstallTrigger !== 'undefined')
  const isChromeRef = useRef(navigator.userAgentData?.brands?.some(b => b.brand === 'Google Chrome'))
  // const isLoggedInRef = useRef(utils.readCookie("loggedin"))

  // useEffect(() => {
  //   const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
  //     setUser(user)
  //     setFirebaseInitialized(true)
  //     utils.writeCookie("loggedin", true)
  //   });
  //   return () => unregisterAuthObserver();
  // }, []);

  useEffect(() => {
    const pingApi = () => {
      axios
        .get('ping')
        .then(r => {
          if (r.data === 'pong') {
            setPingSuccess(true)
          } else {
            console.error('Incorrect pong data')
            setPingSuccess(false)
          }
        })
        .catch(err => {
          setPingSuccess(false)
          console.error('Ping failure', err)
          setTimeout(pingApi, 7000)
        })
    }

    pingApi()
  }, [setPingSuccess])

  const onPhotoOrientationChange = useCallback((orientation) => {
    setPhotoOrientation(orientation)
  }, [])

  return (<div className='NewApp'>
    <div id="container" className={photoOrientation}>
      {/* {user && <div id="signout-container">
        <button className="signout" onClick={() => firebase.auth().signOut()}>Sign out</button>
      </div>} */}
      <LogoSvg id="logo" />
      <span className="logo-text">Instant Process</span>
      {!isWebGLSupported ?
        <Webgl2FailureMessage /> :
        !pingSuccess ?
          <PingFailureMessage /> :
          // (!user && firebaseInitialized) ?
            // <Login onSuccessfulLogin={(user => setUser(user))} /> :
            // (user || isLoggedInRef.current) ?
              <FileView onPhotoOrientationChange={onPhotoOrientationChange} /> 
              // : null
      }
    </div>
    <div id="footer">
      <div id="footer-left">
        {isFirefoxRef.current ?
          <FirefoxExtensionLink /> :
          isChromeRef.current ?
            <ChromeExtensionLink /> :
            null
        }
      </div>
      <div id="footer-right">
        {/* {user && <button className="signout" onClick={() => firebase.auth().signOut()}>Sign out</button>} */}
        <div className="email"><a href="mailto:carl@protou.ch">carl@protou.ch</a></div>
        <span className="copyright">© Carl Öst Wilkens 2022</span>
      </div>
    </div>
  </div>)
}


export default App
